<script>
import IconColorMixin from '@/mixins/IconColorMixin';

export default defineNuxtComponent({
  mixins: [IconColorMixin],
  props: {
    iconName: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    accent: {
      type: String,
      default: 'currentColor',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 11L12 7L7.5 11"
      stroke="#F5AA3B"
      stroke-opacity="0.5"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M16.5 17L12 13L7.5 17"
      stroke="#F5AA3B"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
